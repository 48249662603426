<template>
  <div>
    <br /><br />
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container v-else>
      <b-row>
        <b-col md="12">
          <div
            class="d-flex border border-info rounded p-2 justify-content-center"
          >
            <h4 style="font-size: 18px; font-weight: bolder">
              Survey Name:<span class="text-info"> {{ survey.name }}</span>
            </h4>
            <h6 class="ml-2" style="font-size: 18px; font-weight: bolder">
              Survey Type: <span class="text-info">Program Exit Survey</span>
            </h6>
          </div>

          <br />

          <h6 class="text-danger" v-if="answerPostData.length == 0">
            Survey Questions not assigned yet, Please check back later!
          </h6>
          <b-form @submit.prevent="preparePostDataForSurveyAnswers()" v-else>
            <div class="choiceOptions">
              <b-overlay
                :show="answerSurvey"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="md"
              >
                <div
                  v-for="(choice, choiceIndex) in answerPostData"
                  :key="choiceIndex"
                >
                  <div class="border border-info rounded p-3 mb-3">
                    <div
                      class="questionNoAndQuestion d-flex align-items-center"
                    >
                      <p style="font-size: 16px; font-weight: bolder">
                        {{ choice.question_no }}:
                      </p>
                      <p style="font-size: 16px; font-weight: 600" class="ml-2">
                        {{ choice.name }}
                        <b-badge
                          v-for="(po, poIndex) in choice.programme_outcomes"
                          :key="poIndex"
                          variant="info"
                          class="text-white"
                          :id="'po' + po.id"
                          >{{ po.name }}</b-badge
                        >
                        <b-badge
                          v-for="(pso,
                          psoIndex) in choice.programme_specificoutcomes"
                          :key="psoIndex"
                          variant="success"
                          class="text-white"
                          :id="'pso' + pso.id"
                          >{{ pso.name }}</b-badge
                        >

                        <!-- <b-tooltip
                          v-if="
                            choice.programme_outcome_id != null &&
                              coursePoAndPso['PO']
                          "
                          :target="'po' + choice.programme_outcome_id"
                          triggers="hover"
                        >
                          {{
                            coursePoAndPso["PO"][choice.programme_outcome_id]
                              .description == ""
                              ? "Description not found!"
                              : coursePoAndPso["PO"][
                                  choice.programme_outcome_id
                                ].description
                          }}
                        </b-tooltip>

                        <b-tooltip
                          v-if="
                            choice.programme_specificoutcome_id != null &&
                              coursePoAndPso['PSO']
                          "
                          :target="'pso' + choice.programme_specificoutcome_id"
                          triggers="hover"
                        >
                          {{
                            coursePoAndPso["PSO"][
                              choice.programme_specificoutcome_id
                            ].description == ""
                              ? "Description not found!"
                              : coursePoAndPso["PSO"][
                                  choice.programme_specificoutcome_id
                                ].description
                          }}
                        </b-tooltip> -->
                      </p>
                    </div>
                    <div class="options">
                      <b-form-radio
                        style="font-size: 14px; font-weight: 600"
                        v-for="(option, optionIndex) in choice.choices"
                        :key="optionIndex"
                        v-model="choice.survey_choice_id"
                        :value="option.id"
                        :class="{
                          'form-group-error':
                            $v.answerPostData.$each[choiceIndex]
                              .survey_choice_id.$error,
                        }"
                        >{{ option.name }}</b-form-radio
                      >
                      <span
                        class="input-error-message"
                        v-if="
                          !$v.answerPostData.$each[choiceIndex].survey_choice_id
                            .required &&
                            $v.answerPostData.$each[choiceIndex]
                              .survey_choice_id.$dirty
                        "
                        >Please Select One!</span
                      >
                    </div>
                    <div
                      class="feedbackInputField"
                      v-if="
                        choice.allow_descriptive == 1 ||
                          choice.choices.length == 0
                      "
                    >
                      <b-form-input
                        v-model="choice.answer"
                        placeholder="Write Feedback....."
                        :class="{
                          'form-group-error':
                            $v.answerPostData.$each[choiceIndex].answer.$error,
                        }"
                      ></b-form-input>
                      <span
                        class="input-error-message"
                        v-if="
                          !$v.answerPostData.$each[choiceIndex].answer
                            .required &&
                            $v.answerPostData.$each[choiceIndex].answer.$dirty
                        "
                        >Please Provide a feedback!</span
                      >
                    </div>
                  </div>
                </div>
              </b-overlay>
              <!-- </div> -->
            </div>
            <br />
            <div class="submitAnswers text-center">
              <b-button
                variant="primary"
                size="md"
                type="submit"
                :disabled="answerBtn.disabled"
                >{{ answerBtn.text }}</b-button
              >
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  async created() {
    await this.getSurvey();
  },
  data() {
    return {
      fetchData: false,
      answerSurvey: false,
      survey: {},
      choices: [],
      selected: [],
      surveyData: [],
      answerPostData: [],
      courseTeacherCombination: [],
      answerBtn: {
        text: "Submit",
        disabled: false,
      },
      questionsAndChoices: [], //testing------------
      answers: {}, // testing---------------
      surveyAnswers: {},
      // coursePoAndPso: {},
    };
  },
  validations: {
    surveyAnswers: {
      required: requiredIf(function() {}),
    },
    answerPostData: {
      required,
      $each: {
        answer: {
          required: requiredIf(function($each) {
            if ($each.choices.length == 0) {
              return true;
            }
          }),
        },
        survey_choice_id: {
          required: requiredIf(function($each) {
            if ($each.choices.length != 0) {
              return true;
            }
          }),
        },
      },
    },
  },

  methods: {
    //********* Prepare Post Data for Survey Answers **********/
    async preparePostDataForSurveyAnswers() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.$smoothScroll({
          scrollTo: document.querySelector(".form-group-error"),
          duration: 1000,
          offset: -120,
        });
        this.submitStatus = "ERROR";
      } else {
        this.answerBtn.text = "Please wait...";
        this.answerBtn.disabled = true;
        this.answerSurvey = true;
        let surveyAnswers = await {
          studfbsurveyanswers: [],
        };

        this.answerPostData.forEach((question) => {
          surveyAnswers.studfbsurveyanswers.push({
            course_id: question.course_id,
            teacher_id: question.teacher_id,
            survey_question_id: question.survey_question_id,
            survey_choice_id: question.survey_choice_id,
            answer: question.answer,
          });
        });

        const submitSurveyAnswers = await this.submitSurveyAnswers(
          surveyAnswers
        );
        if (submitSurveyAnswers) {
          this.answerBtn.text = "Submitted";
          this.surveyAnswers = false;
          await this.$toast.success("Responses Submittd, Thank you :)", {
            position: "top",
            duration: 4000,
          });
          this.fetchData = await true;
          await this.$router.push({ name: "institute" });
        }
        if (!submitSurveyAnswers) {
          this.$toast.error("Something went wrong, Please try again!", {
            position: "top",
            duration: 3000,
          });
          this.clearResponse();
        }
      }
    },
    //********* Submit Survey Answers with API **********/
    async submitSurveyAnswers(surveyAnswers) {
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey +
        `/${this.survey.id}/studfbsurveyanswer`;
      let status = null;
      await this.$axios
        .post(url, surveyAnswers)
        .then((response) => {
          response;
          status = true;
          response;
        })
        .catch((error) => {
          error;
          status = true;
          // error;
        });
      return status;
    },
    //********* Getting Survey Details **********/
    async getSurvey() {
      const surveyId = await this.$route.params.survey_id;
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey + `/${surveyId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.survey = response.data;
          // this.getPoAndPos();
          this.startSurvey();

          response;
        })
        .catch((error) => {
          error;
          error;
        });
    },
    //********* Start Survey **********/
    async startSurvey() {
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey +
        `/${this.survey.id}/start`;
      await this.$axios
        .get(url)
        .then((response) => {
          response;
          this.answerPostData = [];
          this.courseTeacherCombination = [];

          this.surveyData = response.data.questionsChoices;
          this.courseTeacherCombination = response.data.surveyCourceTeachers;
          this.questionsAndChoices = response.data.questionsChoices; // testing-------

          this.surveyData.forEach((question) => {
            this.answerPostData.push({
              course_id: null,
              teacher_id: null,
              survey_question_id: question.id,
              survey_choice_id: "",
              answer: "",
              question_no: question.question_no,
              name: question.name,
              allow_descriptive: question.allow_descriptive,
              answer_required: question.answer_required,
              choices: question.choices,
              programme_outcomes: question.programme_outcomes,
              programme_specificoutcomes: question.programme_specificoutcomes,
            });
          });

          response;

          this.fetchData = false;
        })
        .catch((error) => {
          error;
        });
    },
    // async getPoAndPos() {
    //   await this.getProgramOutcomes();
    //   await this.getProgramSpecificOutcomes();
    // },

    // async getProgramOutcomes() {
    //   const url = this.$store.getters.getAPIKey.programOutcome;
    //   await this.$axios
    //     .get(url)
    //     .then((response) => {
    //       const programOutcomes = response.data;
    //       this.coursePoAndPso = {};
    //       this.$set(this.coursePoAndPso, "PO", {});
    //       programOutcomes.forEach((po) => {
    //         this.$set(this.coursePoAndPso["PO"], po.id, {});
    //         this.$set(this.coursePoAndPso["PO"][po.id], "name", po.code);
    //         this.$set(this.coursePoAndPso["PO"][po.id], "description", po.outcome);
    //       });

    //       response;
    //     })
    //     .catch((error) => {
    //       error;
    //     });
    // },
    // async getProgramSpecificOutcomes() {
    //   const url = this.$store.getters.getAPIKey.programSpecificOutcome;
    //   await this.$axios
    //     .get(url)
    //     .then((response) => {
    //       const programSpecificOutcomes = response.data;
    //       this.$set(this.coursePoAndPso, "PSO", {});
    //       programSpecificOutcomes.forEach((pso) => {
    //         this.$set(this.coursePoAndPso["PSO"], pso.id, {});
    //         this.$set(this.coursePoAndPso["PSO"][pso.id], "name", pso.code);
    //         this.$set(this.coursePoAndPso["PSO"][pso.id], "description", pso.outcome);
    //       });
    //       response;
    //     })
    //     .catch((error) => {
    //       error;
    //     });
    // },
    clearResponse() {
      this.answerBtn.text = "Submit";
      this.surveyAnswers = false;
      setTimeout(() => {
        this.answerBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>
